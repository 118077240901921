import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { authLogin,authLogout, authSettingsSetup } from '../store/authSlice';
import { postData } from "../services/api";


const ProtectedRoute = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [auth, setAuth] = useState(false)
  let token_ = localStorage.getItem('accessToken');
  
  useEffect(() =>  {
    async function fetchData() {
      const settingsData = await postData("site-setting", {}, "GET");
      const userData = await postData("verify-token", {},"POST");
      if(userData?.data && token_){
        if(userData?.status && userData?.status === 200){
          setAuth(true);
          dispatch( authLogin(userData?.data) );
          dispatch( authSettingsSetup(settingsData?.data) );
        }else{
          setAuth(false);
          dispatch( authLogout() );
          localStorage.removeItem('accessToken');
          navigate('/signin')
        }
        return
      }else{
        setAuth(false);
        dispatch( authLogout());
        localStorage.removeItem('accessToken');
        navigate('/signin')
        return
      }
    }
    fetchData();
  }, [dispatch])

  return (
    <>
    {
      auth && props.children
    }
    </>
  )
}

export default ProtectedRoute;


