import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import { Link } from 'react-router-dom';
import toastr from "toastr";
import { useNavigate } from "react-router";

import { postData } from "../services/api";
import Image from "../components/elements/Image";

const ForgotPassword = () => {
  setTitle("Zuso | Forgot Password");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isCheck, setIsCheck] = useState(1);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isResendOtp, setIsResendOtp] = useState(false);
  const [countDown, setCountDown] = useState('(00:00)');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsForgotPassword(true);
    try {
      const userData = await postData("forgot-password", {
        email: email
      });
      if (userData.status && userData.status === 200) {
        setIsForgotPassword(false);
        setIsCheck(2);
        otpCounter();
        toastr.success(userData.message);
      } else {
        toastr.error(userData.message);
        setIsForgotPassword(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsForgotPassword(false);
    }
  };

  const onVerify = async (e) => {
    e.preventDefault();
    setIsVerify(true);
    try {
      const userData = await postData("verify-otp", {
        email: email,
        otp: otp,
      });
      if (userData.status && userData.status === 200) {
        setIsVerify(false);
        setIsCheck(3);
        setOtp('');
        toastr.success(userData.message);
      } else {
        toastr.error(userData.message);
        setIsVerify(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsVerify(false);
    }
  };

  const onResendOtp = async (e) => {
    e.preventDefault();
    setIsResendOtp(true);
    try {
      const userData = await postData("resend-otp", {
        email: email,
      });
      if (userData.status && userData.status === 200) {
        setIsResendOtp(false);
        toastr.success(userData.message);
      } else {
        toastr.error(userData.message);
        setIsResendOtp(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsResendOtp(false);
    }
  };

  const onChangePassword = async (e) => {
    e.preventDefault();
    setIsChangePassword(true);
    try {
      const userData = await postData("reset-password", {
        email: email,
        password: password,
        confirm_password: confirmPassword,
      });
      if (userData.status && userData.status === 200) {
        setIsChangePassword(false);
        toastr.success(userData.message);
        return navigate("/signin");
      } else {
        toastr.error(userData.message);
        setIsChangePassword(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsChangePassword(false);
    }
  };

  const otpCounter = () => {
    setIsDisabled(true);
    const count_time5 = add_minutes(new Date(), 0.5).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    const count_time20 = new Date(count_time5).getTime();
    const x20 = setInterval(function () {
      const timezone20 = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
      const now20 = new Date(timezone20).getTime();
      const distance20 = count_time20 - now20;
      const days20 = Math.floor(distance20 / (1000 * 60 * 60 * 24));
      const hours20 = Math.floor((distance20 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes20 = Math.floor((distance20 % (1000 * 60 * 60)) / (1000 * 60));
      const seconds20 = Math.floor((distance20 % (1000 * 60)) / 1000);
      setCountDown('(' + ('0' + minutes20).slice(-2) + ":" + ('0' + seconds20).slice(-2) + ')');
      if (distance20 < 0) {
        clearInterval(x20);
        setCountDown('(00:00)');
        setIsDisabled(false);
      }
    }, 1000);
  }

  const add_minutes = (dt2, minutes2) => {
    return new Date(dt2.getTime() + minutes2 * 60000);
  }

  return (
    <>
      <div className="w-full min-h-screen flex bg-slate-900 relative">
        <div className="fixed top-0 left-0 w-full h-full">
          <Image
            src={"bg-login.jpg"}
            alt={'Admin'}
            width={'100%'}
            height={'100%'}
            effect={'blur'}
            classes={'object-cover'}
          />
        </div>
        <div className="w-full max-w-lg p-10 relative z-10 flex flex-col justify-center bg-neutral-900/80 backdrop-blur-sm">
          <div className="w-full max-w-xs m-auto">
            {(isCheck && isCheck === 1) ? (
              <>
                <div className="divide-y divide-neutral-200/20 space-y-10">
                  <div className="relative">
                    <h1 className="text-2xl font-semibold text-center text-white mb-2">
                      Forgot Password
                    </h1>
                    <div className="text-sm text-slate-300 text-center font-thin">
                      Please use your Email address to reset password
                    </div>
                  </div>
                  <form className="pt-4 space-y-6">
                    <div className="relative">
                      <Input
                        label={"Email Address"}
                        labelClasses={"!text-white !font-thin !text-sm"}
                        inputClasses={"!text-white/80 !font-light !bg-neutral-900/50 !border-neutral-600 focus:!border-primary-500 !h-12 transition-all duration-200"}
                        inputType={"email"}
                        inputPlaceholder={"Email address"}
                        inputValue={email}
                        setInput={setEmail}
                      />
                    </div>

                    <div className="relative flex justify-center">
                      <Button
                        buttonClasses="w-full !h-12"
                        buttonLabelClasses="uppercase"
                        buttonType={"button"}
                        buttonIcon={(isForgotPassword) ? "fa-light fa-spinner fa-spin" : "fa-light fa-arrow-right-to-arc"}
                        buttonIconPosition={"right"}
                        buttonLabel={"Reset Password"}
                        functions={onSubmit}
                        buttonHasLink={false}
                        buttonDisabled={isForgotPassword}
                      />
                    </div>
                    <div className="relative flex items-center justify-center">
                      <div className="text-sm text-white font-thin">
                        Already have a account? <Link to={"/signin"} className="text-primary-500 font-medium text-sm mx-1">Signin</Link> here
                      </div>
                    </div>
                  </form>
                </div>
              </>
            ) : (isCheck && isCheck === 2) ? (
              <>
                <div className="divide-y divide-neutral-200/20 space-y-10">
                  <div className="relative">
                    <h1 className="text-2xl font-semibold text-center text-white mb-2">
                      Verification code
                    </h1>
                    <div className="text-sm text-slate-300 text-center font-thin">
                      Please check your email for account verification
                    </div>
                  </div>
                  <form className="pt-4 space-y-6">
                    <div className="relative">
                      <Input
                        label={"Verification Code"}
                        labelClasses={"!text-white !font-thin !text-sm"}
                        inputClasses={"!text-white/80 !font-light !bg-neutral-900/50 !border-neutral-600 focus:!border-primary-500 !h-12 transition-all duration-200"}
                        inputType={"number"}
                        inputPlaceholder={"Enter OTP"}
                        inputValue={otp}
                        setInput={setOtp}
                        errorType={"counter"}
                        errorText={countDown}
                      />
                    </div>
                    <div className="relative grid grid-cols-2 gap-4">
                        <Button
                          buttonClasses="w-full !h-12"
                          buttonLabelClasses="uppercase"
                          buttonType={"button"}
                          buttonIcon={(isVerify) ? "fa-light fa-spinner fa-spin" : "fa-light fa-arrow-right-to-arc"}
                          buttonIconPosition={"left"}
                          buttonLabel={"Submit"}
                          functions={onVerify}
                          buttonHasLink={false}
                          buttonDisabled={isVerify}
                        />
                        <Button
                          buttonLabelClasses="uppercase"
                          buttonClasses={(isDisabled) ? '!w-full !h-12 pointer-events-none !opacity-60' : '!w-full !h-12'}
                          buttonType={"button"}
                          buttonIcon={(isResendOtp) ? "fa-light fa-spinner fa-spin" : "fa-light fa-clock"}
                          buttonIconPosition={"left"}
                          buttonLabel={"Resend OTP"}
                          functions={onResendOtp}
                          buttonHasLink={false}
                          buttonDisabled={isDisabled}
                        />
                      </div>
                    <div className="relative flex items-center justify-center">
                      <div className="text-sm text-white font-thin">
                        Already have a account? <Link to={"/signin"} className="text-primary-500 font-medium text-sm mx-1">Signin</Link> here
                      </div>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <>
                <div className="divide-y divide-neutral-200/20 space-y-10">
                  <div className="relative">
                    <h1 className="text-2xl font-semibold text-center text-white mb-2">
                      Change Password
                    </h1>
                    <div className="text-sm text-slate-300 text-center font-thin">
                      Please use your credentials to update password
                    </div>
                  </div>
                  <form className="pt-4 space-y-6">
                    <div className="relative">
                      <Input
                          label={"Password"}
                          labelClasses={"!text-white !font-thin !text-sm"}
                          inputClasses={"!text-white/80 !font-light !bg-neutral-900/50 !border-neutral-600 focus:!border-primary-500 !h-12 transition-all duration-200"}
                          inputType={"password"}
                          inputPlaceholder={"Your Password"}
                          inputValue={password}
                          setInput={setPassword}
                        //errorType={''}
                        />
                    </div>
                    <div className="relative">
                        <Input
                          label={"Confirm Password"}
                          labelClasses={"!text-white !font-thin !text-sm"}
                          inputClasses={"!text-white/80 !font-light !bg-neutral-900/50 !border-neutral-600 focus:!border-primary-500 !h-12 transition-all duration-200"}
                          inputType={"password"}
                          inputPlaceholder={"Confirm Password"}
                          inputValue={confirmPassword}
                          setInput={setConfirmPassword}
                        //errorType={''}
                        />
                      </div>

                    <div className="relative flex justify-center">
                      <Button
                          buttonClasses="w-full !h-12"
                          buttonLabelClasses="uppercase"
                          buttonType={"button"}
                          buttonIcon={(isChangePassword) ? "fa-light fa-spinner fa-spin" : "fa-light fa-arrow-right-to-arc"}
                          buttonIconPosition={"left"}
                          buttonLabel={"Change Password"}
                          functions={onChangePassword}
                          buttonHasLink={false}
                          buttonDisabled={isChangePassword}
                        />
                    </div>
                    <div className="relative flex items-center justify-center">
                      <div className="text-sm text-white font-thin">
                        Already have a account? <Link to={"/signin"} className="text-primary-500 font-medium text-sm mx-1">Signin</Link> here
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>


      {/* <div className="w-full min-h-screen flex justify-center items-center bg-slate-100 py-10">
        <div className="w-full max-w-sm">
          <div className="h-10 w-full flex justify-center object-contain mb-5">
            Zuso | Forgot Password
          </div>
          <div className="w-full bg-white rounded shadow-md py-8 px-6">
            {
              (isCheck && isCheck === 1) ?
                <div className="divide-y divide-slate-200 space-y-4">
                  <div className="relative">
                    <h1 className="text-xl font-semibold text-center text-slate-700">
                      Forgot Password
                    </h1>
                    <div className="text-sm text-slate-400 text-center font-light">
                      Please use your credentials to forgot password
                    </div>
                  </div>
                  <div className="pt-4 space-y-4">
                    <div className="relative">
                      <Input
                        label={"Email"}
                        labelClasses={"!text-xs"}
                        inputType={"email"}
                        inputPlaceholder={"Email address"}
                        inputValue={email}
                        setInput={setEmail}
                      />
                    </div>
                    <div className="relative flex justify-center">
                      <Button
                        buttonClasses=""
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={(isForgotPassword) ? "fa-light fa-spinner fa-spin" : "fa-light fa-arrow-right-to-arc"}
                        buttonIconPosition={"left"}
                        buttonLabel={"Forgot Password"}
                        functions={onSubmit}
                        buttonHasLink={false}
                        buttonDisabled={isForgotPassword}
                      />
                    </div>
                  </div>
                  <div className="flex gap-1 item-center justify-center text-slate-400 text-sm pt-4">
                    Already have a account?
                    <Link to={"/signin"} className="text-primary-500 font-semibold">Signin</Link>
                    here
                  </div>
                </div> : (isCheck && isCheck === 2) ?
                  <div className="divide-y divide-slate-200 space-y-4">
                    <div className="relative">
                      <h1 className="text-xl font-semibold text-center text-slate-700">Verification code</h1>
                      <div className="text-sm text-slate-400 text-center font-light">
                        Please check your email for account verification
                      </div>
                    </div>
                    <div className="pt-4 space-y-4">
                      <div className="relative">
                        <Input
                          label={"Verification Code"}
                          labelClasses={"!text-xs"}
                          inputType={"number"}
                          inputPlaceholder={"Enter OTP"}
                          inputValue={otp}
                          setInput={setOtp}
                          errorType={"info"}
                          errorText={countDown}
                        />
                      </div>
                      <div className="relative flex justify-center gap-4">
                        <Button
                          buttonClasses=""
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={(isVerify) ? "fa-light fa-spinner fa-spin" : "fa-light fa-arrow-right-to-arc"}
                          buttonIconPosition={"left"}
                          buttonLabel={"Submit"}
                          functions={onVerify}
                          buttonHasLink={false}
                          buttonDisabled={isVerify}
                        />
                        <Button
                          buttonLabelClasses=""
                          buttonClasses={(isDisabled) ? '!bg-slate-200 !text-slate-600 border border-slate-300 pointer-events-none' : ''}
                          buttonType={"button"}
                          buttonIcon={(isResendOtp) ? "fa-light fa-spinner fa-spin" : "fa-light fa-clock"}
                          buttonIconPosition={"left"}
                          buttonLabel={"Resend OTP"}
                          functions={onResendOtp}
                          buttonHasLink={false}
                          buttonDisabled={isDisabled}
                        />
                      </div>
                    </div>
                  </div> :
                  <div className="divide-y divide-slate-200 space-y-4">
                    <div className="relative">
                      <h1 className="text-xl font-semibold text-center text-slate-700">
                        Change Password
                      </h1>
                      <div className="text-sm text-slate-400 text-center font-light">
                        Please use your credentials to update password
                      </div>
                    </div>
                    <div className="pt-4 space-y-4">
                      <div className="relative">
                        <Input
                          label={"Password"}
                          labelClasses={"!text-xs"}
                          inputType={"password"}
                          inputPlaceholder={"Your Password"}
                          inputValue={password}
                          setInput={setPassword}
                        //errorType={''}
                        />
                      </div>
                      <div className="relative">
                        <Input
                          label={"Confirm Password"}
                          labelClasses={"!text-xs"}
                          inputType={"password"}
                          inputPlaceholder={"Your Password"}
                          inputValue={confirmPassword}
                          setInput={setConfirmPassword}
                        //errorType={''}
                        />
                      </div>
                      <div className="relative flex justify-center">
                        <Button
                          buttonClasses=""
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={(isChangePassword) ? "fa-light fa-spinner fa-spin" : "fa-light fa-arrow-right-to-arc"}
                          buttonIconPosition={"left"}
                          buttonLabel={"Change Password"}
                          functions={onChangePassword}
                          buttonHasLink={false}
                          buttonDisabled={isChangePassword}
                        />
                      </div>
                    </div>
                  </div>
            }
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ForgotPassword;