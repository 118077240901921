import SidebarMenuBlocks from "../components/navigation/SidebarMenuBlocks";
import { useEffect, useState } from "react";
import { postData } from "../services/api";
import Image from "../components/elements/Image";
const DefaultSidebar = ({ isMenuOpen, setIsMenuOpen }) => {
  const [logo, setLOgo] = useState("");
  const [favicon, setFavicon] = useState("");

  useEffect(() => {
    const loadLogo = async () => {
      const result = await postData("site-setting", {},'GET');
      if (result.status === 200) {
        setLOgo(result.data.logo);
        setFavicon(result.data.favicon);
      }
    };
    loadLogo();
  }, []);

  const sidebarMenu = [
    {
      _id: "0",
      menuTitle: "Dashboard",
      menuData: [
        {
          _id: "1-1",
          menuLabel: "Dashboard",
          menuIsLink: true,
          menuLink: "/dashboard",
          menuIcon: "fa-gauge-max",
          menuIconColor: "text-primary-500",
        },
      ],
    },
    {
      _id: "1",
      menuTitle: "User Managemant",
      menuData: [
        {
          _id: "1-0",
          menuLabel: "Chefs",
          menuIsLink: true,
          menuLink: "/chefs",
          menuIcon: "fa-users",
          menuIconColor: "text-primary-500",
        },
        {
          _id: "1-1",
          menuLabel: "Users",
          menuIsLink: true,
          menuLink: "/users",
          menuIcon: "fa-users",
          menuIconColor: "text-primary-500",
        },
        {
          _id: "1-2",
          menuLabel: "Admins",
          menuIsLink: true,
          menuLink: "/admins",
          menuIcon: "fa-user-crown",
          menuIconColor: "text-primary-500",
        },
      ],
    },
    {
      _id: "2",
      menuTitle: "Recipe Managemant",
      menuData: [
        // {
        //   _id: "2-0",
        //   menuLabel: "Themes",
        //   menuIsLink: false,
        //   menuIcon: "fa-grid-2-plus",
        //   menuIconColor: "text-primary-500",
        //   submenuData: [
        //     {
        //       _id: "2-0-1",
        //       menuLabel: "List",
        //       menuLink: "/categories/recipe",
        //     },
        //     {
        //       _id: "2-0-2",
        //       menuLabel: "Sub Category List",
        //       menuLink: "/sub-categories",
        //     },
        //   ],
        // },
        {
          _id: "3-1",
          menuLabel: "Recipes",
          menuIsLink: false,
          menuIcon: "fa-mortar-pestle",
          menuIconColor: "text-primary-500",
          submenuData: [
            {
              _id: "3-1-1",
              menuLabel: "Themes",
              menuLink: "/categories/recipe",
              menuIcon: "fa-list",
              menuIconColor: "text-primary-500",
            },
            {
              _id: "3-1-2",
              menuLabel: "Meal Times",
              menuLink: "/mealtimes",
              menuIcon: "fa-wheat",
              menuIconColor: "text-primary-500",
            },
            {
              _id: "3-1-2",
              menuLabel: "Defficults",
              menuLink: "/defficults",
              menuIcon: "fa-wheat",
              menuIconColor: "text-primary-500",
            },
            {
              _id: "3-1-3",
              menuLabel: "Recipes",
              menuLink: "/recipe",
              menuIcon: "fa-cutlery",
              menuIconColor: "text-primary-500",
            },
            // {
            //   _id: '3-1-4',
            //   menuLabel: 'Meal Plans',
            //   menuLink: '/mealplans',
            //   menuIcon: 'fa-cutlery',
            //   menuIconColor: 'text-primary-500',
            // },
            // {
            //   _id: '3-1-5',
            //   menuLabel: 'Assign Mealplan',
            //   menuLink: '/assign-mealplan',
            //   menuIcon: 'fa-cutlery',
            //   menuIconColor: 'text-primary-500',
            // },
            // {
            //   _id: "3-1-5",
            //   menuLabel: "Appliances",
            //   menuLink: "/appliances",
            //   menuIcon: "fa-diagram-lean-canvas",
            //   menuIconColor: "text-primary-500",
            // },
          
          ],
        },
        // {
        //   _id: "2-1",
        //   menuLabel: "Meal Plan",
        //   menuIsLink: false,
        //   menuIcon: 'fa-cutlery',
        //   menuIconColor: 'text-primary-500',
        //   submenuData: [
        //     {
        //       _id: "2-0-1",
        //       menuLabel: "List View",
        //       menuLink: "/mealplans-list",
        //       menuIcon: "fa-cutlery",
        //       menuIconColor: "text-primary-500",
        //     },
        //     {
        //       _id: "2-0-2",
        //       menuLabel: "Calander View",
        //       menuLink: "/meal-plan-calander",
        //       menuIcon: "fa-cutlery",
        //       menuIconColor: "text-primary-500",
        //     },
        //   ],
        // },
      ],
    },

    // {
    //   _id: "4",
    //   menuTitle: "Class Managemant",
    //   menuData: [
    //     {
    //       _id: "4-0",
    //       menuLabel: "Achievements",
    //       menuIsLink: true,
    //       menuLink: "/achievements",
    //       menuIcon: "fa-award",
    //       menuIconColor: "text-primary-500",
    //     },
    //     {
    //       _id: "4-1",
    //       menuLabel: "Classes",
    //       menuIsLink: true,
    //       menuLink: "/classes",
    //       menuIcon: "fa-screen-users",
    //       menuIconColor: "text-primary-500",
    //     },
    //     {
    //       _id: "4-2",
    //       menuLabel: "Subscription",
    //       menuIsLink: true,
    //       menuLink: "/subscriptions",
    //       menuIcon: "fa-at",
    //       menuIconColor: "text-primary-500",
    //     },
    //   ],
    // },

    // {
    //   _id: "5",
    //   menuTitle: "Grocery Managemant",
    //   menuData: [
    //     {
    //       _id: "5-0",
    //       menuLabel: "Groceries",
    //       menuIsLink: false,
    //       menuIcon: "fa-salad",
    //       menuIconColor: "text-primary-500",
    //       submenuData: [
    //         {
    //           _id: "5-0-1",
    //           menuLabel: "Category List",
    //           menuLink: "/categories/grocery",
    //         },
    //         {
    //           _id: "5-0-2",
    //           menuLabel: "Grocery List",
    //           menuLink: "/groceries",
    //         },
    //       ],
    //     },
    //   ],
    // },

    {
      _id: "6",
      menuTitle: "CMS",
      menuData: [
        {
          _id: "6-1",
          menuLabel: "Email Template",
          menuIsLink: true,
          menuLink: "/email-templates",
          menuIcon: "fa-envelope",
          menuIconColor: "text-primary-500",
        },
        {
          _id: "6-2",
          menuLabel: "Settings",
          menuIsLink: true,
          menuLink: "/site-settings",
          menuIcon: "fa-gear",
          menuIconColor: "text-primary-500",
        },
      ],
    },
  ];

  return (
    <>
      <div className="relative w-full bg-slate-900 divide-y divide-slate-800">
        <div
          className={
            "relative flex items-center gap-4 h-16 transition-all duration-100 " +
            (isMenuOpen ? "px-3" : "px-4")
          }
        >
          <div className="w-full h-10">
            {logo && favicon && (
              <>
                {!isMenuOpen ? (
                  <Image
                    src={logo}
                    alt={"Logo"}
                    id={"sidebarLogo"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain"}
                  />
                ) : (
                  <Image
                    src={favicon}
                    alt={"Logo"}
                    id={"sidebarLogo"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain"}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div
          className={
            "relative overflow-auto scroll-smooth scrollbar scrollbar-1 h-[calc(100vh-4rem)] transition-all duration-100 " +
            (isMenuOpen ? "divide-y divide-slate-800" : "")
          }
        >
          {sidebarMenu.map((item) => (
            <SidebarMenuBlocks
              key={item._id}
              menuTitle={item.menuTitle}
              menuData={item.menuData}
              isMenuOpen={isMenuOpen}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default DefaultSidebar;
